import React from 'react'
import styled from 'styled-components';

const H3 = ({ children }) => {
    return (
        <H3Title>
            {children}
        </H3Title>
    );
};

export default H3;

const H3Title = styled.h3`
    color: #5007c5;
    padding: 0 0 10px 10px;
    font-size: 16px;
    margin-bottom: 0px;
`;