import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import styled from 'styled-components';

const NotPage = () => {
  return (
    <>
        <Navbar />
        <Content>
            <h1>404 Not Found</h1>
        </Content>
    </>
  );
};

export default NotPage;

const Content = styled.div`
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5007c5;
`;