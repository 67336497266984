import styled from "styled-components";
import { BlogData } from "./BlogData";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import H1 from "../Markdown/H1";
import H2 from "../Markdown/H2";
import H3 from "../Markdown/H3";
import P from "../Markdown/P";
import Ul from "../Markdown/Ul";
import A from "../Markdown/A";
import Em from "../Markdown/Em";
import Image from "../Markdown/Image";
import BackQuote from "../Markdown/BackQuote";
import { Code } from "../Markdown/Code";
import { parseTocMd } from "../Markdown/Markdown";

const BlogPageContainer = () => {
  const params = useParams();
  const blogInfo = BlogData.filter((data) => data.blogId === params.id);

  return (
    <Content>
      <ContentA>
        <ContentTitleInfo>
          <ContentTitle>{blogInfo[0].title}</ContentTitle>
          <ContentTitleDesc>
            <ContentTitleCreatedAt>
              {blogInfo[0].createdAt}
            </ContentTitleCreatedAt>
            <ContentItemTags>
              {blogInfo[0].tags.map((tag, index) => (
                <ContentItemTag key={index}>{tag}</ContentItemTag>
              ))}
            </ContentItemTags>
          </ContentTitleDesc>
        </ContentTitleInfo>

        <ContentDesc>
          <ContentDescIndex>
            <ContentDescIndexTitle>目次</ContentDescIndexTitle>
            <ReactMarkdown
              components={{
                ul: Ul,
                a: A,
              }}
            >
              {parseTocMd(blogInfo[0].context)}
            </ReactMarkdown>
          </ContentDescIndex>

          <ReactMarkdown
            remarkPlugins={[remarkBreaks, remarkGfm]}
            components={{
              h1: H1,
              h2: H2,
              h3: H3,
              p: P,
              pre: Code,
              img: Image,
              blockquote: BackQuote,
              em: Em,
            }}
          >
            {blogInfo[0].context}
          </ReactMarkdown>
        </ContentDesc>
      </ContentA>
    </Content>
  );
};

export default BlogPageContainer;

const Content = styled.div`
  background-color: #f9f4f9f9;
  min-height: 100vh;
`;
const ContentA = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: 0 auto 0 auto;
  background-color: white;
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;
const ContentTitleInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px dotted #e0d0fa;
  margin: 60px 50px 0 50px;
  padding: 50px 10px 5px 10px;

  @media screen and (max-width: 768px) {
    margin: 40px auto 0px auto;
    width: 80%;
  }
`;
const ContentTitle = styled.h1`
  font-size: 28px;
  color: #5007c5;
  /* padding: 50px 10px 5px 10px; */
  /* margin: 70px 50px 0 50px; */

  @media screen and (max-width: 768px) {
    font-size: 36px;
    padding: 0;
    margin-bottom: 0;
    text-align: left;
  }
`;
const ContentTitleDesc = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const ContentTitleCreatedAt = styled.p`
  padding-left: 8px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const ContentItemTags = styled.div`
  font-size: 12px;
  padding: 2px 10px;
  display: flex;
  gap: 8px;
`;
const ContentItemTag = styled.button`
  font-size: 12px;
  background-color: white;
  border: 1px solid #e0d0fa;
  border-radius: 8px;
  padding: 4px 8px;
  &:hover {
    color: #bc9feb;
    cursor: pointer;
    transform: translate(1px, 1px);
    transition: 0.2s;
  }
`;
const ContentDesc = styled.div`
  width: 90%;
  margin: 10px auto 0 auto;
  padding-bottom: 50px;
`;
const ContentDescIndex = styled.div`
  padding-left: 15px;
  border: 1px solid #e0d0fa;
  border-radius: 5px;
  margin: 40px auto 40px auto;
  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 20px auto;
    padding: 0 5px 0 5px;
  }
`;
const ContentDescIndexTitle = styled.h2`
  padding-left: 15px;
  margin-bottom: 0;
  font-size: 22px;
`;
