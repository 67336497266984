import React from "react";
import styled from "styled-components";

const Hero = () => {
  return (
    <Content>
      <ContentTitle>
        <Title>
          初心者から上級者まで！
          <br />
          Web開発サークルを一緒に立ち上げよう
        </Title>
      </ContentTitle>
      <ContentImage src={`${process.env.PUBLIC_URL}/circle.png`} />
    </Content>
  );
};

export default Hero;

const Content = styled.div`
  width: 100vw;
  height: 75vh;
  margin: 70px auto 30px auto;
  /* background-color: #f9f4f9; */
  background-image: url("${process.env.PUBLIC_URL}/Hero.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dotted #e0d0fa;
  @media screen and (max-width: 768px) {
    height: 45vh;
  }
`;
const ContentTitle = styled.div`
  position: relative;
  margin: 0 15px;
`;
const Title = styled.h1`
  text-align: center;
  font-size: 52px;
  font-weight: 800;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const ContentImage = styled.img`
  width: 630px;
  height: 630px;
  object-fit: cover;
  opacity: 0.37;
  position: absolute;
  @media screen and (max-width: 768px) {
    width: 350px;
    height: 350px;
  }
`;
