import React from 'react'
// import { useParams } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
import BlogPageContainer from './BlogPageContainer';

const BlogPage = () => {
  // const params = useParams();
  // console.log(params.id);

  return (
    <>
        <Navbar />
        <BlogPageContainer />
    </>
  );
};

export default BlogPage;

