import React from 'react'
import Main from './Main'
import Hero from './Hero';
import Contact from './Contact';


const Content = () => {
  return (
    <>  
        <Hero />
        <Main />
        <Contact />
    </>
  )
};


export default Content;