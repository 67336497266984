export const mardown001 = `
# Test01
Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!

## これはMarkdownのサンプルです
## Markdown
Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!

### aaa

Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo!

# Test02
Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, nemo! [Google](https://www.google.com)

[Google](https://www.google.com)

~~~typescript:utils/hello.ts
import styled from 'styled-components';

const test = () => {
    console.log("Hello World");
};

test();
~~~

~~~python:utils/hello.py
class Test:
    def __init__(self):
        txt = "Hello World"

    def __call__(self):
        print(txt)

test = Test()
test()
~~~


# Test03
![image](${process.env.PUBLIC_URL}/BlogImage01.png)![image](${process.env.PUBLIC_URL}/BlogImage01.png)
![image](${process.env.PUBLIC_URL}/BlogImage02.jpg)
![image](${process.env.PUBLIC_URL}/BlogImage03.jpg)

~AAAA~

- list1
- list2

1. list1
2. list2

| Header1 | Header2 |
| ------- | ------- |
| data1   | data2   |
| data3   | data4   |

*Bold*
nkavanvnavlae*Bold*kalvnlkanvlan *annvanvnenvbqsnvn*

## これはMarkdownのサンプルです

- [x] Task1
- [ ] Task2

BlockQuote
> BlockQuote
>> BlockQuote2

<details>

Just a link: www.nasa.gov.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.
>> A block quote with ~strikethrough~ and a URL: https://reactjs.org.
>>> A block quote with ~strikethrough~ and a URL: https://reactjs.org.
`;