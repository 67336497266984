import React from 'react'
import styled from 'styled-components';

const P = ({ children }) => {
    return (
        <PTitle>
            {children}
        </PTitle>
    );
};

export default P;

const PTitle = styled.p`
    color: black;
    padding: 0 0 0 10px;
    font-size: 16px;
    margin-top: 0px;
`;