import React from 'react';
import styled from 'styled-components';

const BackQuote = ({ children }) => {
  return (
    <BackQuoteContainer>
        {children}
    </BackQuoteContainer>
  )
}

export default BackQuote;

const BackQuoteContainer = styled.blockquote`
    border-left: 5px solid #5007c5;
    padding-left: 10px;
`;