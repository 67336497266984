import React from 'react'
import Content from '../components/Content/Content';
import Navbar from '../components/Navbar/Navbar';

const Home = () => {
  return (
    <>
      <Navbar />
      <Content />
    </>
  )
}

export default Home;