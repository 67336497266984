import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BlogData } from './BlogData';

const BlogContainer = () => {
  const datas = BlogData;
  const [selectTag, setSelectTag] = useState("全て");
  const [filteredData, setFilteredData] = useState(datas);
  const filter = ["Vercel", "React", "Node.js", "Python", "全て"];

  useEffect(() => {
    if (selectTag === "全て") {
        setFilteredData(datas);
    } else {
        let filtered = datas.filter((data) => {
            return data.tags.includes(selectTag);
        });
        setFilteredData(filtered);
    }
  }, [selectTag]);


  const handleFilter = (e) => {
    setSelectTag(e.target.innerText);
  };

  return (
    <Content>
        <ContentA>
            <ContentWrapper>
                <ContentTitle>
                    Blog
                    <ContentSmallTitle>(ブログ)</ContentSmallTitle>
                </ContentTitle>

                <ContentFilter>
                    {filter.map((tag) => (
                         <ContentFilterTitle onClick={(e) => handleFilter(e)}>{tag}</ContentFilterTitle>
                    ))}
                </ContentFilter>

                <ContentItems>
                    {filteredData.map((data) => (
                        <ContentItem key={data.id}>

                            <Link to={`/blog/${data.blogId}`} style={{ "textDecoration": "none" }} state={{ data : data }}>
                                <ContentItemTitle>
                                    <ContentItemTitleName>{data.title}</ContentItemTitleName>
                                </ContentItemTitle>
                            </Link>
                            
                            <ContentItemCreatedAt>{data.createdAt}</ContentItemCreatedAt>
                            
                            <ContentItemTags>
                                {data.tags.map((tag) => (
                                    <ContentItemTag onClick={(e) => handleFilter(e)}>{tag}</ContentItemTag>
                                ))}
                            </ContentItemTags>

                        </ContentItem>
                    ))}
                </ContentItems>
            </ContentWrapper>
        </ContentA>
    </Content>
  );
};

export default BlogContainer;

const Content = styled.div`
    background-color: #f9f4f9f9;
    min-height:100vh;
`;
const ContentA = styled.div`
    width: 80vw;
    min-height:100vh;
    margin: 0 auto 0 auto;
    background-color: white;
    @media screen and (max-width: 768px) {
        width: 100vw;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ContentTitle = styled.h1`
  font-size: 70px;
  color: #5007c5;
  border-bottom: 4px dotted #e0d0fa;
  padding: 0 60px 5px 60px;
  margin: 30px;
  margin-top: 105px;
  @media screen and (max-width: 768px) {
    font-size: 42px;
    padding: 0 40px 5px 40px;
    margin-bottom: 5px;
  }
`;
const ContentSmallTitle = styled.span`
  font-size: 16px;
  color: #5007c5;
  padding-left: 6px;
`;

const ContentItems = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 95%;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 80%;
    }
`;
const ContentItem = styled.div`
    border: 3px solid #e0d0fa;
    border-radius: 12px;
    height: 220px;
    box-shadow: 3px 5px 8px 0px #b4b5cb;
    @media screen and (max-width: 768px) {
        height: 180px;
    }
    &:hover {
        cursor: pointer;
        transform: translate(1px, 1px);
        transition: 0.2s;
    }
`;

const ContentItemTitle = styled.div`
    width: 100%;
    height: 70%;
    color: black;
    /* background-color: #e0d0fa; */
    background-image: url("${process.env.PUBLIC_URL}/ContactBackImg.png");
    background-size: cover;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
    @media screen and (max-width: 768px) {
        height: 60%;
    }
`;
const ContentItemTitleName = styled.div`
    margin: 15px;
    font-weight: 600;
    font-size: 22px;
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;
const ContentItemCreatedAt = styled.p`
    padding-left: 4px;
    margin: 8px;
    font-size: 12px;
`;
const ContentItemTags = styled.div`
    font-size: 12px;
    padding: 2px 10px;
    display: flex;
    gap: 8px;
`;
const ContentItemTag = styled.button`
    font-size: 12px;
    background-color: white;
    border: 1px solid #e0d0fa;
    border-radius: 8px;
    padding: 4px 8px;
    &:hover {
        color: #bc9feb;
        cursor: pointer;
        transform: translate(1px, 1px);
        transition: 0.2s;
    }
`;

const ContentFilter = styled.div`
    display: flex;
    gap: 10px;
    margin: 20px 0;
`;
const ContentFilterTitle = styled.button`
    background-color: white;
    border: 2px solid #e0d0fa;
    border-radius: 12px;
    padding: 4px 8px;
    &:hover {
        color: #bc9feb;
        cursor: pointer;
        transform: translate(1px, 1px);
        transition: 0.2s;
    }
`;