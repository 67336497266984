import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const ProfileInfo = ({ data }) => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [figSize, setFigSize] = useState(50);
  const containerRef = useRef();

  /* 画面の横幅を動的に取得する*/
  useEffect(() => {
    // divタグに埋め込んだrefから横幅を取得する
    const handleResize = () => {
        if (containerRef.current) {
            setFigSize(containerRef.current.offsetWidth);
        }
    };
    handleResize();
    // ウィンドウのサイズが変化するたびにhandleResize関数を再実行
    window.addEventListener('resize', handleResize);

    return () => {
        // アンマウント時に無効化
        window.removeEventListener('resize', handleResize);
    };
  }, []);


    /* 所定時間ごとに画像インデックスをインクリメントする
    */
    // const scrollInterval = 7;  // 切り替え秒数を設定
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setScrollIndex((e) => (e + 1) % imgs.length);
    //     }, scrollInterval * 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);


  /* 画面の横幅，または画像インデックスが変わったタイミングで，スクロール位置を調整する */
  useEffect(() => {
    containerRef.current.scrollTo({
        left: scrollIndex * figSize,
        behavior: 'smooth',
    });
  }, [figSize, scrollIndex]);

  return (
    <ContentItem>
        
        <ContentImage>
            <ContentImagPreview ref={containerRef}>
                {data.img.map((img, index) => (
                    <SImage key={index} src={`${process.env.PUBLIC_URL}/${img}`} alt="プロフィール画像" />
                ))}
            </ContentImagPreview>
            <ContentImagPreviewSlider>
                {data.img.map((image, index) => (
                    <ContentImagPreviewButton key={image.id} onClick={() => setScrollIndex(index)}>
                        {index === scrollIndex ? "◉" : "・"}
                    </ContentImagPreviewButton>
                ))}
            </ContentImagPreviewSlider>
        </ContentImage>

        <ContentInfo>
            <ContentItemTitle>ニックネーム</ContentItemTitle>
            <ContentItemText>{data.name}</ContentItemText>

            <ContentItemTitle>所在地</ContentItemTitle>
            <ContentItemText>{data.location}</ContentItemText>

            <ContentItemTitle>得意なこと</ContentItemTitle>
            <ContentItemText>{data.skill}</ContentItemText>

            <ContentItemTitle>やりたいこと</ContentItemTitle>
            <ContentItemText>{data.want}</ContentItemText>
        </ContentInfo>
    </ContentItem>
  );
};

export default ProfileInfo;

const ContentItem = styled.div`
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("${process.env.PUBLIC_URL}/Hero.png");
    background-size: cover;
    border-radius: 20px;
    &:nth-child(even) {
        flex-direction: row-reverse;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-bottom: 20px;
        &:nth-child(even) { 
            flex-direction: column;
        }
        
    }
`;
const ContentImage = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ContentItemTitle = styled.h3`
    margin: 0;
    color: #5007c5;
    font-weight: 600;
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;
const ContentItemText = styled.p`
    margin: 2px 0 15px 0;
    padding-left: 10px;
    font-weight: 580;
    @media screen and (max-width: 768px) {
        font-size: 12px;
        padding-left: 6px;
    }
`;

const ContentImagPreview = styled.div`
    display: flex;
    overflow-x: hidden;
    width: 330px;
    height: 300px;
    object-fit: cover;
    padding: 10px;
    @media screen and (max-width: 768px) {
        padding: 0 5px;
        width: 250px;
        height: 230px;
        margin: 0 30px 0 0;
    }
`;
const SImage = styled.img`
    width: 270px;
    height: 270px;
    object-fit: cover;
    border-radius: 50%;
    margin: 40px 40px 0px 40px;
    @media screen and (max-width: 768px) {
        width: 200px;
        height: 200px;
        object-fit: cover;
        margin: 20px 20px 10px 40px;
    }
`;
const ContentImagPreviewSlider = styled.div`
    display: flex;
    gap: 5px;
    margin: 20px;
    @media screen and (max-width: 768px) {
        margin: 0 0 10px 0;
    }
`;

const ContentImagPreviewButton = styled.button`
    background-color: white;
    color: #5e07eb;
    border: none;
    font-weight: 600;
    border: 1px solid #e0d0fa;
    border-radius: 8px;
`;

const ContentInfo = styled.div`
    flex:1.5;
    margin: 0 20px;
    padding: 20px;
    border: 1px solid #e0d0fa;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        margin: 0 10px;
        padding: 10px;
        width: 80%;
    }
`;