import React from 'react'
import styled from 'styled-components';

const Ul = ({ children }) => {
    return (
        <UlTitle>
            {children}
        </UlTitle>
    );
};

export default Ul;

const UlTitle = styled.ul``;