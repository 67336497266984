import React from 'react'
import styled from 'styled-components';

const H2 = ({ children }) => {
    return (
        <H2Title>
            {children}
        </H2Title>
    );
};

export default H2;

const H2Title = styled.h2`
    color: #5007c5;
    padding: 0 0 10px 10px;
    font-size: 20px;
    margin-bottom: 0px;
`;