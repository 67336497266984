export const ProfileData = [
    {
        id: 1,
        name: 'Hiro',
        img: [
            `BlogImage01.png`,
            `BlogImage02.jpg`,
            `BlogImage03.jpg`,
        ],
        location: '埼玉',
        skill: 'Python・HTML・CSS・JavaScript(React)・AI開発・データ分析',
        want: 'フロント/バックエンド開発・大規模Webアプリ開発・3Dモデリング・行動経済学・デザイン学・現代文学'
    },
    {
        id: 2,
        name: 'XXX XXX',
        img: [
            'BlogImage01.png',
            'BlogImage01.png',
        ],
        location: '東京',
        skill: 'Python・HTML・CSS・JavaScript(React)・AI開発・データ分析',
        want: 'フロント/バックエンド開発・大規模Webアプリ開発'
    },
    {
        id: 3,
        name: 'XXX XXX',
        img: [
            'BlogImage01.png',
            'BlogImage01.png',
        ],
        location: '東京',
        skill: 'Python・HTML・CSS・JavaScript(React)・AI開発・データ分析',
        want: 'フロント/バックエンド開発・大規模Webアプリ開発'
    },
];