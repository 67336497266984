import React from "react";
import styled from "styled-components";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HandymanIcon from "@mui/icons-material/Handyman";
import FlagIcon from "@mui/icons-material/Flag";
import CloudIcon from "@mui/icons-material/Cloud";
import CodeIcon from "@mui/icons-material/Code";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PlaceIcon from "@mui/icons-material/Place";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Main = () => {
  return (
    <Content>
      <ContentTitle>
        About
        <ContentSmallTitle>(何をするのか)</ContentSmallTitle>
      </ContentTitle>

      <ContentItems>
        <ContentItem>
          <ItemTitle>
            <FlagIcon style={{ paddingRight: "6px" }} />
            Goal<SmallTitle>(目標)</SmallTitle>
          </ItemTitle>
          <ItemContentPurpose>
            <PlaceIcon />
            Web開発に必要な技術の習得
          </ItemContentPurpose>
          <ItemContentPurpose>
            <PlaceIcon />
            チーム開発で実践経験を積む
          </ItemContentPurpose>
        </ContentItem>

        <ContentItem>
          <ItemTitle>
            <EventAvailableIcon style={{ paddingRight: "6px" }} />
            Content<SmallTitle>(活動内容)</SmallTitle>
          </ItemTitle>
          <ItemContent>
            <CheckCircleOutlineIcon style={{ paddingRight: "6px" }} />
            勉強会・ワークショップの開催
          </ItemContent>
          <ItemContent>
            <CheckCircleOutlineIcon style={{ paddingRight: "6px" }} />
            メンバー間によるプロジェクト開発
          </ItemContent>
          <ItemContent>
            <CheckCircleOutlineIcon style={{ paddingRight: "6px" }} />
            定期的な進捗共有
          </ItemContent>
        </ContentItem>
      </ContentItems>

      <ContentTec>
        <ItemTitle>
          <HandymanIcon style={{ paddingRight: "6px" }} />
          Technology<SmallTitle>(技術)</SmallTitle>
        </ItemTitle>
        <ItemWrapper2>
          <ItemContent2>
            <FrameTitle>
              <CodeIcon style={{ paddingRight: "6px" }} />
              プログラミング言語
            </FrameTitle>
            <FrameContainer>
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/typescript/typescript-original.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/go/go-original-wordmark.svg" />
            </FrameContainer>
          </ItemContent2>

          <ItemContent2>
            <FrameTitle>
              <ViewModuleIcon style={{ paddingRight: "6px" }} />
              フレームワーク
            </FrameTitle>
            <FrameContainer>
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nextjs/nextjs-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/django/django-plain-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/djangorest/djangorest-original.svg" />
            </FrameContainer>
          </ItemContent2>

          <ItemContent2>
            <FrameTitle>
              <CloudIcon style={{ paddingRight: "6px" }} />
              その他
            </FrameTitle>
            <FrameContainer>
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/googlecloud/googlecloud-original.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/docker/docker-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vercel/vercel-original-wordmark.svg" />
              <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/materialui/materialui-original.svg" />
            </FrameContainer>
          </ItemContent2>
        </ItemWrapper2>
      </ContentTec>

      <ContentTitle>
        Flow
        <ContentSmallTitle>(学習の流れ)</ContentSmallTitle>
      </ContentTitle>

      <ContentFlow>
        <FlowImage src={`${process.env.PUBLIC_URL}/flow.png`} />
        <FlowDesc>
          <FlowDescItems>
            <FlowDescItem>
              <FlowDescItemTitle>01. 目標設定</FlowDescItemTitle>
              <FlowDescItemDesc>
                メンバーそれぞれが目標を設定し，目標達成に向けて学習を進めます．
              </FlowDescItemDesc>
            </FlowDescItem>
            <FlowDescItem>
              <FlowDescItemTitle>02. 勉強会</FlowDescItemTitle>
              <FlowDescItemDesc>
                目標達成に必要な技術を身につけるために，勉強会を通じて学習を進めます．
                Web技術だけでなく，AI・マーケティング・デザインなど幅広い分野の知識を学びます．
              </FlowDescItemDesc>
            </FlowDescItem>
            <FlowDescItem>
              <FlowDescItemTitle>03. チーム開発</FlowDescItemTitle>
              <FlowDescItemDesc>
                勉強会で学んだ技術をベースに，Webアプリ開発に取り組みます．
                GitHub・Dockerなど様々なツールを用いて，チームでの実践経験を積みます．
              </FlowDescItemDesc>
            </FlowDescItem>
            <FlowDescItem>
              <FlowDescItemTitle>04. 評価</FlowDescItemTitle>
              <FlowDescItemDesc>
                メンバー間での評価を通じて，目標達成できたかを話し合います．
                学んだこと・大変だったことを共有し，サークル全体での成長を目指します．
              </FlowDescItemDesc>
            </FlowDescItem>
          </FlowDescItems>
        </FlowDesc>
      </ContentFlow>
    </Content>
  );
};

export default Main;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const ContentItems = styled.div`
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const ContentItem = styled.div`
  border: 1px solid #e0d0fa;
  border-radius: 12px;
  box-shadow: 3px 5px 8px 0px #b4b5cb;
  background-color: white;
  height: 250px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;

const ContentTitle = styled.h1`
  font-size: 70px;
  color: #5007c5;
  border-bottom: 4px dotted #e0d0fa;
  padding: 0 60px 5px 60px;
  margin: 30px;
  @media screen and (max-width: 768px) {
    font-size: 42px;
    padding: 0 40px 5px 40px;
  }
`;

const ContentSmallTitle = styled.span`
  font-size: 16px;
  color: #5007c5;
  padding-left: 6px;
`;

const ContentTec = styled.div`
  margin: 25px auto;
  width: 80%;
  height: 300px;
  border: 1px solid #e0d0fa;
  border-radius: 12px;
  box-shadow: 3px 5px 8px 0px #b4b5cb;
  background-color: white;
  @media screen and (max-width: 941px) {
    height: 350px;
  }
  @media screen and (max-width: 768px) {
    height: 420px;
  }
`;

const ItemTitle = styled.h2`
  text-align: center;
  margin: 20px;
  color: #854ce2;
  font-weight: 600;
  border-bottom: 3px dotted #e0d0fa;
  font-size: 32px;
  padding-bottom: 3px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const SmallTitle = styled.span`
  font-size: 12px;
  padding-left: 6px;
`;

const ItemContent = styled.div`
  text-align: left;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 580;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 5px 0;
  }
`;
const ItemContentPurpose = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 22px;
  font-weight: 580;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin: 10px 0;
    padding: 0 10px 5px 10px;
  }
`;

const FrameTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0d0fa;
  margin: 0 20px;
  padding: 4px;
  border-radius: 24px;
`;
const ItemWrapper2 = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  margin: 0;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const ItemContent2 = styled.div`
  text-align: center;
  padding: 10px 20px;
  font-size: 18px;
  height: 180px;
  border-right: 1px dotted #e0d0fa;
  &:last-child {
    border-right: none;
  }
  @media screen and (max-width: 768px) {
    border-right: none;
    padding: 5px 20px;
    height: 100px;
  }
`;

const FrameContainer = styled.div`
  display: flex;
  justify-content: space-around;
  /* margin: 20px 0; */
  flex-wrap: wrap;
  margin-top: 10px;
`;
const FrameImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 10px;
  object-fit: cover;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 400px) {
    width: 24px;
    height: 24px;
  }
`;

const ContentFlow = styled.div`
  display: flex;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 80%;
    flex-direction: column;
  }
`;
const FlowImage = styled.img`
  width: 40%;
  height: 40%;
  object-fit: cover;
  flex: 1;
  @media screen and (max-width: 768px) {
    width: 80%;
    height: 80%;
  }
`;
const FlowDesc = styled.div`
  flex: 1;
`;
const FlowDescItems = styled.div``;
const FlowDescItem = styled.div`
  font-size: 22px;
  border: 1px solid #e0d0fa;
  border-radius: 12px;
  margin: 24px 10px;
  padding: 8px;
  font-weight: 580;
  box-shadow: 3px 5px 8px 0px #b4b5cb;
`;
const FlowDescItemTitle = styled.div`
  padding-left: 10px;
  border-bottom: 2px dotted #e0d0fa;
  @media screen and (max-width: 768px) {
    padding-left: 5px;
    font-size: 18px;
  }
`;

const FlowDescItemDesc = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 3px 0;
  padding: 0 10px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
