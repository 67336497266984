import { mardown001 } from "../../markdown/markdown001";
import { mardown002 } from "../../markdown/markdown002";

export const BlogData = [
    {
        id: 1,
        title: 'サンプル1',
        createdAt: '2024/08/04',
        tags: [ "Vercel", "React", "Node.js" ],
        blogId: "001",
        context: mardown001,
        recBlogId: [
            "002",
            "003",
            "004",
        ],
    },
    {
        id: 2,
        title: 'サンプル2',
        createdAt: '2024/08/05',
        tags: [ "Python" ],
        blogId: "002",
        context: mardown002,
    },
    {
        id: 3,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "Vercel" ],
        blogId: "003",
    },
    {
        id: 4,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "Node.js" ],
        blogId: "004",
    },
    {
        id: 5,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "React", "Node.js" ],
        blogId: "005",
    },
    {
        id: 6,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "Vercel", "React", "Node.js" ],
        blogId: "006",
    },
    {
        id: 7,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "Vercel", "React", "Node.js" ],
        blogId: "007",
    },
    {
        id: 8,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "Vercel", "Node.js" ],
        blogId: "008",
    },
    {
        id: 9,
        title: 'Vercelを活用して実際にデプロイしてみた',
        createdAt: '2024/08/04',
        tags: [ "Vercel", "React", "Node.js" ],
        blogId: "009",
    },
];