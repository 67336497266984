import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Home } from "@mui/icons-material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HardwareIcon from "@mui/icons-material/Hardware";

const Navbar = () => {
  return (
    <NavbarContainer>
      <NavbarIcons0>
        <Link to="/" style={{ textDecoration: "none" }}>
          <SImg src={`${process.env.PUBLIC_URL}/circle.png`} />
        </Link>
      </NavbarIcons0>

      <NavbarIcons1>
        {/* <Link to="/" style={{ textDecoration: "none" }}>
          <NavbarIcon>
            <Home style={{ fontSize: "30px" }} />
            <IconName>ホーム</IconName>
          </NavbarIcon>
        </Link> */}
        <Link to="/profile" style={{ textDecoration: "none" }}>
          <NavbarIcon>
            {/* <AccountCircleIcon style={{ fontSize: "30px" }} /> */}
            <SAccountCircleIcon />
            <IconName>メンバー</IconName>
          </NavbarIcon>
        </Link>
        <Link to="/blog" style={{ textDecoration: "none" }}>
          <NavbarIcon>
            {/* <ReceiptLongIcon style={{ fontSize: "30px" }} /> */}
            <SReceiptLongIcon />
            <IconName>ブログ</IconName>
          </NavbarIcon>
        </Link>
        <Link to="/blog" style={{ textDecoration: "none" }}>
          <NavbarIcon>
            <SHardwareIcon />
            {/* <ReceiptLongIcon style={{ fontSize: "30px" }} /> */}
            <IconName>制作物</IconName>
          </NavbarIcon>
        </Link>
      </NavbarIcons1>

      <NavbarIcons2>
        <Link
          to="https://x.com/AppMake1067615"
          style={{ textDecoration: "none" }}
        >
          <FrameImage src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/twitter/twitter-original.svg" />
        </Link>
      </NavbarIcons2>
    </NavbarContainer>
  );
};

export default Navbar;

const NavbarContainer = styled.div`
  width: 100%;
  height: 75px;
  background-color: white;
  border-bottom: 2px solid #e0d0fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const NavbarIcons0 = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    margin-left: 5px;
    overflow-x: scroll;
    width: 17%;
    /* border-right: 1px solid #e0d0fa; */
  }
`;

const NavbarIcons1 = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    margin-left: 10px;
    overflow-x: scroll;
    width: 68%;
  }
`;

const NavbarIcons2 = styled.div`
  margin: 0 40px 0 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    margin: 0 0 0 10px;
    overflow-x: scroll;
    width: 15%;
    /* border-left: 1px solid #e0d0fa; */
  }
`;

const NavbarIcon = styled.div`
  font-weight: 550;
  color: #854ce2;
  margin: 0;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0d0fa;
  border-radius: 12px;
  height: 32px;
  &:hover {
    color: #bc9feb;
    cursor: pointer;
    transform: translate(2px, 2px);
    transition: 0.2s;
  }
  @media screen and (max-width: 768px) {
    padding: 5px 8px;
    width: 72px;
    height: 28px;
  }
`;

const IconName = styled.div`
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 0;
  }
`;

const FrameImage = styled.img`
  width: 33px;
  height: 32px;
  padding: 10px;
  object-fit: cover;
  /* margin-right: 10px; */
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 400px) {
    width: 18px;
    height: 18px;
    padding-right: 0;
  }
`;

const SImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const SAccountCircleIcon = styled(AccountCircleIcon)`
  font-size: 30px;
  @media screen and (max-width: 768px) {
    font-size: 1px;
  }
`;

const SReceiptLongIcon = styled(ReceiptLongIcon)`
  font-size: 30px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const SHardwareIcon = styled(HardwareIcon)`
  font-size: 30px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
