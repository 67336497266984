import React from 'react'
import styled from 'styled-components';

const H1 = ({ children }) => {
    return (
        <H1Title>
            {children}
        </H1Title>
    );
};

export default H1;

const H1Title = styled.h1`
    color: #5007c5;
    border-bottom: 1px solid #5007c5;
    padding: 0 0 10px 10px;
    font-size: 28px;
`;