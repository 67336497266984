import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import ProfileContainer from '../components/Profile/ProfileContainer'

const Profile = () => {
  return (
    <>
        <Navbar />
        <ProfileContainer />
    </>
  )
}

export default Profile