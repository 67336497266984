export const mardown002 = `
# 見出し1

~~~javascript
# 見出し1
~~~

## 見出し2

~~~javascript
## 見出し2
~~~

### 見出し3

~~~javascript
### 見出し3
~~~

# コーディング
## リンク01
[Google](https://www.google.com)
~~~javascript
[Google](https://www.google.com)
~~~

## リンク02
Just a link: www.nasa.gov.
~~~javascript
Just a link: www.nasa.gov.
~~~


## コードブロック
~~~typescript:utils/hello.ts
import styled from 'styled-components';

console.log("Hello World");
~~~

↓

~~~javascript
~~~typescript:utils/hello.ts
import styled from 'styled-components';

console.log("Hello World");
~~~

## 画像
![image](${process.env.PUBLIC_URL}/BlogImage01.png)![image](${process.env.PUBLIC_URL}/BlogImage01.png)

~~~javascript
![image](${process.env.PUBLIC_URL}/BlogImage01.png)![image](${process.env.PUBLIC_URL}/BlogImage01.png)
~~~

## 引用
> BlockQuote
>> BlockQuote2
>>> BlockQuote3
~~~javascript
> BlockQuote
>> BlockQuote2
>>> BlockQuote3
~~~

## マーク
*This is a pen*
~~~javascript
*This is a pen*
~~~

## リスト
- list1
- list2
~~~javascript
- list1
- list2
~~~

`