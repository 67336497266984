import React from 'react'
import styled from 'styled-components';

const Em = ({ children }) => {
    return (
        <EmContainer>
            <EmTitle>
                {children}
            </EmTitle>
        </EmContainer>
    );
};

export default Em;

const EmContainer = styled.span`
    margin: 0 2px;
    padding: 0 6px;
    background-color: red;
    border-radius: 2px;
    background-color: rgba(235, 235, 235, 1);
`;

const EmTitle = styled.em`
    color: black;
`;