import { unified } from "unified";
import remarkParse from "remark-parse";

export const parseTocMd = (body) => {
    const root = unified().use(remarkParse).parse(body)
    let tocMd = ""
    
    if (root.children) {
      root.children.map((node) => {
        if (
          node.type === "heading" &&
          node?.children[0] &&
          "value" in node?.children[0]
        ) {
          if (node.depth === 1) {
            tocMd += `- [${node?.children[0].value}](#${encodeURIComponent(
              node.children[0].value
            )})\n`
          } else if (node.depth === 2) {
            tocMd += `  - [${node?.children[0].value}](#${encodeURIComponent(
              node.children[0].value
            )})\n`
          } else if (node.depth === 3) {
            tocMd += `    - [${node?.children[0].value}](#${encodeURIComponent(
              node.children[0].value
            )})\n`
          }
        }
      })
    }
  
    return tocMd
};

export const getTitle = (props) => {
    return props.node?.children[0] && "value" in props.node?.children[0]
      ? props.node?.children[0].value
      : ""
};