import React from "react";
import styled from "styled-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DoneIcon from "@mui/icons-material/Done";

const Contact = () => {
  const LimitItems = [
    "20代・30代の方",
    "関東圏にお住まいの方",
    "対面開催で出席可能な方",
  ];

  return (
    <Content>
      <ContentWapper>
        <ContentTitle>
          Create
          <SmallTitle>(作る)</SmallTitle>
        </ContentTitle>

        <ContentBottom>
          <ContentLimit>
            <ContentDesctitle>条件</ContentDesctitle>
            <ContentLimitItems>
              {LimitItems.map((item, index) => (
                <ContentLimitItem key={index}>
                  <DoneIcon style={{ paddingRight: "12px" }} />
                  {item}
                </ContentLimitItem>
              ))}
            </ContentLimitItems>
          </ContentLimit>

          <ContentDesc>
            下記のフォームよりお問い合わせください
            <EntryA href="https://forms.gle/mYXn4YwVV61ua1y76">
              <EntryButton>
                お問い合わせフォームへ
                <OpenInNewIcon />
              </EntryButton>
            </EntryA>
          </ContentDesc>
        </ContentBottom>
      </ContentWapper>
    </Content>
  );
};

export default Contact;

const Content = styled.div`
  height: 600px;
  /* background-color: #f9f4f9; */
  background-image: url("${process.env.PUBLIC_URL}/ContactBackImg.png");
  background-size: cover;
  @media screen and (max-width: 768px) {
    height: 650px;
  }
`;

const ContentWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentTitle = styled.h1`
  font-size: 70px;
  color: #5007c5;
  border-bottom: 4px dotted #e0d0fa;
  padding: 0 60px 5px 60px;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    font-size: 42px;
    padding: 0 40px 5px 40px;
  }
`;

const SmallTitle = styled.span`
  font-size: 16px;
  color: #5007c5;
  padding-left: 6px;
`;
const ContentBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  width: 80%;
  @media screen and (max-width: 768px) {
    gap: 50px;
    flex-direction: column;
  }
`;
const ContentLimit = styled.div`
  flex: 1;
  border: 1px solid #e0d0fa;
  background-color: white;
  border-radius: 12px;
  box-shadow: 3px 5px 8px 0px #b4b5cb;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const ContentLimitItems = styled.ul`
  list-style: none;
  font-size: 20px;
  font-weight: 580;
  margin: 20px 30px;
  @media screen and (max-width: 768px) {
    margin: 10px 45px;
    padding: 0;
  }
  @media screen and (max-width: 1020px) {
    margin: 10px 20px;
    padding: 0;
  }
`;
const ContentLimitItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  @media screen and (max-width: 768px) {
    padding: 10px 5px;
    font-size: 15px;
  }
`;
const ContentDesctitle = styled.div`
  font-weight: 600;
  font-size: 30px;
  color: #854ce2;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 30px;
    flex-direction: column;
    margin: 20px 0 10px 0;
    border-bottom: 3px dotted #e0d0fa;
    padding: 0 40px 0 40px;
  }
`;
const ContentDesc = styled.p`
  font-size: 20px;
  flex: 1;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const EntryA = styled.a`
  text-decoration: none;
  margin-top: 15px;
`;
const EntryButton = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  padding: 20px 40px;
  border: 1px solid #e0d0fa;
  border-radius: 12px;
  box-shadow: 3px 5px 8px 0px #b4b5cb;
  background-color: white;
  color: #854ce2;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #bc9feb;
    cursor: pointer;
    transform: translate(2px, 2px);
    transition: 0.2s;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 70%;
    margin: auto;
    margin-top: 12px;
  }
`;
