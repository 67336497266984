import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import BlogContainer from '../components/Blog/BlogContainer';

const Blog = () => {
  return (
    <>
      <Navbar />
      <BlogContainer />
    </>
  );
};

export default Blog;