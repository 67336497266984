import React from 'react'
import styled from 'styled-components';

const A = ({ children }) => {
    return (
        <ATitle>
            {children}
        </ATitle>
    );
};

export default A;

const ATitle = styled.a`
    font-weight: 500;
    font-size: 16px;
`;