import React from 'react'
import styled from 'styled-components';

const Image = ({ node, ...props }) => {
  return (
    <SImage {...props}/>
  );
};

export default Image;

const SImage = styled.img`
    max-width: 80%;
    max-height: 350px;
    object-fit: cover;
    margin-right: 10px;
`;