import React from "react";
import styled from "styled-components";
import ProfileInfo from "./ProfileInfo";
import { ProfileData } from "./ProfileData";

const ProfileContainer = () => {
  const profileData = ProfileData;

  return (
    <Content>
      <ContentWrapper>
        <ContentTitle>
          Member
          <ContentSmallTitle>(メンバー)</ContentSmallTitle>
        </ContentTitle>

        <ContentItems>
          {profileData.map((data) => (
            <ProfileInfo key={data.id} data={data} />
          ))}
        </ContentItems>
      </ContentWrapper>
    </Content>
  );
};

export default ProfileContainer;

const Content = styled.div`
  background-color: #f9f4f9f9;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0 auto 0 auto;
  width: 80vw;
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.h1`
  font-size: 70px;
  color: #5007c5;
  border-bottom: 4px dotted #e0d0fa;
  padding: 0 60px 5px 60px;
  margin: 30px;
  margin-top: 105px;
  @media screen and (max-width: 768px) {
    font-size: 38px;
    padding: 0 40px 5px 40px;
  }
`;
const ContentSmallTitle = styled.span`
  font-size: 16px;
  color: #5007c5;
  padding-left: 6px;
`;

const ContentItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
