import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';

export const Code = ({ children, ...props }) => {
    if (!children || typeof children !== 'object') {
      return <code {...props}>{children}</code>
    };

    const childType = 'type' in children ? children.type : '';

    if (childType !== 'code') {
      return <code {...props}>{children}</code>
    };

    const childProps = 'props' in children ? children.props : {};
    const { className, children: code } = childProps;
    const classList = className ? className.split(':') : [];
    const language = classList[0]?.replace('language-', '');
    const fileName = classList[1];

    return (
      <CodeWrapper>
        {fileName && (
          <FileNameContainer>
            <span>{fileName}</span>
          </FileNameContainer>
        )}
        <CodeContainer>
            <CodeBlock language={language} style={dracula}>
                    {String(code).replace(/\n$/, '')}
            </CodeBlock>
        </CodeContainer>
      </CodeWrapper>
    );
};

const CodeWrapper = styled.div`
    padding-left: 10px;
`;

const FileNameContainer = styled.div`
    background-color: rgba(40, 42, 54, 1);
    border-radius: 5px 5px 0 0;
    padding: 5px 5px 5px 10px;
    color: white;
    border-bottom: 1px solid white;
    font-weight: 600;
`;

const CodeContainer = styled.div`
    margin-top: -13px;
`;

const CodeBlock = styled(SyntaxHighlighter)`
    border-radius: 0 0 5px 5px;
`;